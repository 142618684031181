import dynamic from "next/dynamic"
import { useEffect, useMemo } from "react"
import { useInView } from "react-intersection-observer"

import { TrackedComponent, TrackedComponents } from "@spatialsys/react/analytics"
import { useGetFeatureFlagsQuery } from "@spatialsys/react/query-hooks/feature-flags"
import { useAppContext, useAuthState } from "@spatialsys/web/app-context"
import { TryToAuthenticate } from "@spatialsys/web/core/js/components/auth/try-to-authenticate"
import { Footer } from "@spatialsys/web/core/js/components/footer/footer"
import { Navbar } from "@spatialsys/web/core/js/components/navbar/navbar"
import { SpacesFeed } from "@spatialsys/web/core/js/components/spaces-feed/spaces-feed"
import { Container } from "@spatialsys/web/ui"

import { FeaturedPlayer } from "./featured-player"
import { HeroCarousel } from "./hero-carousel/hero-carousel"

import classes from "./homepage.module.scss"

type HomepageProps = {
  userAgent: string
}

export function Homepage({ userAgent }: HomepageProps) {
  // If authenticated and onboarding is not complete, we redirect to onboarding
  const { isAuthenticated, isAuthless } = useAuthState()
  const actions = useAppContext((context) => context.actions)
  const featuredInView = useAppContext((context) => context.state.canvas.isFeaturedInView)

  const ffQuery = useGetFeatureFlagsQuery()
  const isJumpCutEnabled = ffQuery.data?.featureFlags?.jumpCut

  const threshold = useMemo(() => {
    if (!isJumpCutEnabled) {
      return 0.1
    }
    return featuredInView ? 0 : 0.5
  }, [featuredInView, isJumpCutEnabled])

  const [featuredRef] = useInView({
    threshold,
    onChange: actions.setFeaturedInView,
  })

  useEffect(() => {
    return () => actions.setFeaturedInView(null)
  }, [actions])

  const navbarColor: React.ComponentProps<typeof Navbar>["color"] = useMemo(() => {
    if (isJumpCutEnabled) {
      return "translucent"
    }

    // Check explicitly for null here, we want to default to transparent on the initial render to avoid a flash from black to transparent
    if (featuredInView === null) {
      return "transparent"
    }
    return featuredInView ? "transparent" : undefined
  }, [featuredInView, isJumpCutEnabled])

  return (
    <TrackedComponent id={TrackedComponents.Homepage} as="div" className="flex min-h-screen flex-col">
      <TryToAuthenticate>
        <Navbar applyNegativeMarginTop={!isJumpCutEnabled} color={navbarColor} />
        <div className={classes.mainContent}>
          {isJumpCutEnabled ? (
            <FeaturedPlayer ref={featuredRef} closedFallback={<HeroCarousel inView={Boolean(featuredInView)} />} />
          ) : (
            <div className="h-[80vh] max-h-[1280px] min-h-[480px]">
              <HeroCarousel ref={featuredRef} inView={Boolean(featuredInView)} />
            </div>
          )}
          <div className={classes.spacesContainer}>
            <Container className="flex flex-col content-center">
              <SpacesFeed userAgent={userAgent} />
            </Container>
          </div>

          {(!isAuthenticated || isAuthless) && <MarketingContent />}
        </div>
        <Footer />
      </TryToAuthenticate>
    </TrackedComponent>
  )
}

const FreeOnlineGames = dynamic(() =>
  import("./marketing-sections/free-online-games/free-online-games").then((mod) => mod.FreeOnlineGames)
)

const BuildOnSpatial = dynamic(() =>
  import("./marketing-sections/build-on-spatial/build-on-spatial").then((mod) => mod.BuildOnSpatial)
)

const BrandExperiences = dynamic(() =>
  import("./marketing-sections/brand-experiences/brand-experiences").then((mod) => mod.BrandExperiences)
)
const Press = dynamic(() => import("./marketing-sections/press/press").then((mod) => mod.Press))

const WhatIsSpatial = dynamic(() =>
  import("./marketing-sections/what-is-spatial/what-is-spatial").then((mod) => mod.WhatIsSpatial)
)

function MarketingContent() {
  return (
    <section className="w-full space-y-12 pb-4 xs:pb-6 md:space-y-20 md:pb-8 lg:space-y-24 xl:space-y-28 2xl:space-y-32">
      <FreeOnlineGames />
      <BuildOnSpatial />
      <BrandExperiences />
      <Press />
      <WhatIsSpatial />
    </section>
  )
}
